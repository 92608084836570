import React, { useEffect } from "react"
import { Link, navigate } from "gatsby"
import Seo from "../components/Frontend/Seo"
import AuthLayout from "../components/AuthLayout"
import styled from "styled-components"

const PageContainer = styled.div`
  .welcome {
    font-family: "Montserrat", "IBM Plex Sans Thai", sans-serif;
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 15px;
  }
  .link {
    font-family: "Montserrat", "IBM Plex Sans Thai", sans-serif;
    font-weight: 500;
    font-size: 18px;
    text-decoration: underline;
  }
`

const IndexPage = () => {
  let storageUser = null
  const isBrowser = typeof window !== "undefined"
  if (isBrowser) {
    storageUser = JSON.parse(localStorage.getItem("firebaseLocalStorage"))
  }

  useEffect(() => {
    if (isBrowser) {
      navigate("/auth/signin")
      return null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AuthLayout>
      <Seo title={`Solar e-Doc`} description={``} />
      <PageContainer>
        {storageUser && storageUser?.uid ? (
          <>
            <h2 className="welcome">
              Welcome, {storageUser?.firstName || storageUser?.email}
            </h2>
            <Link className="link" to="/backend/dashboard">
              Go to Dashboard
            </Link>
          </>
        ) : (
          <Link className="link" to="/auth/signin">
            Sign In
          </Link>
        )}
      </PageContainer>
    </AuthLayout>
  )
}

export default IndexPage
